import { ToastService } from 'services/toast-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, bindable } from 'aurelia-framework';
import { NetDimensionService } from 'services/net-dimension-service';
import { NetService } from 'services/net-service';
import { ErrorService } from 'services/error-service';
import { ValidationController } from 'aurelia-validation';
import { I18N } from 'aurelia-i18n';
import { Utility } from '../../utility';
import { Prompt } from 'elements/prompt';
import { DialogService } from 'aurelia-dialog';
import { SimpleOfferTabBase } from './simple-offer-tab-base';
import { NetOfferService } from 'services/net-offer-service';

@autoinject
export class SimpleOfferTabAnalysis extends SimpleOfferTabBase {
  @bindable private netId: number;
  @bindable private net;
  @bindable private locked = false;
  private formIsValid = true;
  private nextTabIndex: number = null;
  private originalObject;
  private changeTabSubscription: Subscription;

  constructor(
    private eventAggregator: EventAggregator,
    private netDimensionService: NetDimensionService,
    private dialogService: DialogService,
    private netService: NetService,
    private toastService: ToastService,
    private validationController: ValidationController,
    private i18n: I18N,
    private utility: Utility,
    errorService: ErrorService,
    netOfferService: NetOfferService
  ) {
    super(netOfferService, errorService);
  }

  private async activate(params: { Id: number; NetId: number }) {
    this.netId = params.NetId || params.Id;
    await this.setOfferIsOrder(params.Id);
    void this.getNet(this.netId);

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.changeTabSubscription = this.eventAggregator.subscribe('changeTab', (tabIndex) => {
      this.nextTabIndex = tabIndex;
    });
  }

  private detached() {
    if (this.changeTabSubscription) {
      this.changeTabSubscription.dispose();
    }
  }

  private deactivate() {
    if (this.changeTabSubscription) {
      this.changeTabSubscription.dispose();
    }
  }

  // Get net and netdimension
  private getNet(id) {
    this.netService
      .get(id + '?$expand=NetDimension')
      .then((res) => {
        this.net = res;
        this.locked = res.Locked || this.netIsLinkedToAnalysis(res.NetDimension) || this.offerIsOrder ? true : false;
        this.addValidationRules();
        this.updateOriginalObject();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private addValidationRules() {
    /*     ValidationRules.ensure("NetTypeId")
      .required()
      .withMessage(this.i18n.tr("general.requiredField"))
      .on(this.net); */
  }

  private validateForm() {
    this.validationController.validate().then((result) => {
      if (result.valid) {
        this.formIsValid = true;
      } else {
        this.formIsValid = false;
      }
    });
  }

  private async validateAsync(): Promise<any> {
    return new Promise<void>(async (resolve, reject) => {
      this.validationController
        .validate()
        .then((result) => {
          if (result.valid) {
            this.formIsValid = true;
            resolve();
          } else {
            this.formIsValid = false;
            reject();
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private updateOriginalObject() {
    if (!this.net) {
      return;
    }
    this.originalObject = JSON.parse(JSON.stringify(this.net));
  }

  private async save(): Promise<any> {
    return new Promise<void>(async (resolve, reject) => {
      this.netDimensionService
        .put(this.net.NetDimension, this.net.NetDimension.Id)
        .then((res) => {
          this.toastService.showSuccess('analysis.updated');
          this.updateOriginalObject();
          resolve();
        })
        .catch((err) => this.errorService.handleError(err));
    });
  }

  private changeTab(direction = 'next') {
    if (direction === 'prev') {
      this.eventAggregator.publish('triggerPrevTab', true);
    } else {
      this.eventAggregator.publish('triggerNextTab', true);
    }
  }

  private saveAndChangeTab(direction = 'next') {
    // does it validate ?
    this.validateAsync()
      .then(() => {
        // check if different
        if (!this.utility.areEqual(this.net, this.originalObject, true)) {
          // save
          this.save().then((res) => {
            this.changeTab(direction);
          });
        } else {
          this.changeTab(direction);
        }
      })
      .catch((error) => {
        this.toastService.showWarning('general.checkValidationErrors');
      });
  }

  private canDeactivate() {
    this.validateForm();
    if (!this.formIsValid) {
      this.toastService.showWarning('general.checkValidationErrors');
      return false;
    }

    if (this.originalObject && !this.utility.areEqual(this.net, this.originalObject, true) && !this.locked) {
      // tslint:disable-next-line:no-console
      return this.dialogService
        .open({
          viewModel: Prompt,
          model: {
            header: 'dialog.pleaseConfirmHeader',
            message: 'dialog.unsavedChangesText',
          },
        })
        .whenClosed((response) => {
          if (response.wasCancelled) {
            return false;
          } else {
            const result = response.output;
            if (result === 'save') {
              // save the nettingtype and let that function handle the rest of the logic
              this.save();
              return false;
            } else {
              this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
              return true;
            }
          }
        });
    } else {
      this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
      return true;
    }
  }
}
