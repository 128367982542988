import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { NetService } from 'services/net-service';
import { SinkerTubeService } from 'services/sinker-tubes-service';
import { ExtraEquipmentService, ExtraEquipmentTypeService } from 'services';
import { ExtraEquipment, ExtraEquipmentType, ExtraEquipmentTypes, SinkerTube } from 'models/ExtraEquipment';
import { Utility } from 'utility';
import { ChangeTabDirection, SimpleOfferTabBase } from './simple-offer-tab-base';
import { NetOfferService } from 'services/net-offer-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ToastService } from 'services/toast-service';
import { Router } from 'aurelia-router';
import './simple-offer-tab-extra-equipment.scss';

type OfferRouteArgs = {
  Id: number;
  NetId: number;
};

type SelectdValues = {
  Id?: number;
  NetId: number;
  ExtraEquipmentTypeId: number;
  Quntity?: number;
  SinkerTubeId?: number;
  Value1?: number;
  Value2?: number;
  Comment?: string;
  Show: boolean;
  HideQuantity?: boolean;
};

type ExtraEquipmentTypeValues = { [key: number]: SelectdValues };

@autoinject
export class SimpleOfferTabExtraEquipment extends SimpleOfferTabBase {
  private netId: number;
  private offerId: number;

  protected net: Models.Net;
  protected sinkerTubes: SinkerTube[] = [];
  protected extraEquipmentTypes: ExtraEquipmentType[] = [];
  protected extraEquipmentForNet: ExtraEquipment[] = [];

  protected locked = false;

  protected extraEquipemntValues: ExtraEquipmentTypeValues = {};
  private extraEquipemntValuesTrackerObject: ExtraEquipmentTypeValues = {};

  constructor(
    private sinkerTubeService: SinkerTubeService,
    private extraEquipmentTypeService: ExtraEquipmentTypeService,
    private extraEquipmentService: ExtraEquipmentService,
    private netService: NetService,
    private utility: Utility,
    private router: Router,
    errorService: ErrorService,
    netOfferService: NetOfferService,
    aggregator: EventAggregator,
    toast: ToastService
  ) {
    super(netOfferService, errorService, aggregator, toast);
  }

  async activate({ Id, NetId }: OfferRouteArgs) {
    if (!NetId) {
      this.netId = +Id;
    } else {
      this.netId = +NetId;
      this.offerId = +Id;
    }

    await this.setup();
  }

  async setup() {
    try {
      await Promise.all([
        this.getAllSinkerTubes(),
        this.getAllExtraEquipmentTypes(),
        this.getExtraEquipmentForNet(this.netId),
        this.getNet(this.netId),
      ]);

      if (this.router.baseUrl.includes('offer')) {
        await this.setOfferIsOrder(this.offerId);
      }

      const deadFish = this.net.NetDimension?.DeadFishSystem?.Id == 1;
      const liftUp = this.net.NetDimension?.DeadFishSystem?.Id == 2;
      const deadFishAndLiftUp = this.net.NetDimension?.DeadFishSystem?.Id > 2;

      // Create table
      this.extraEquipemntValues = this.extraEquipmentTypes.reduce((acc, current) => {
        let show = true;
        let hideQuantity = false;

        if (current.Type === ExtraEquipmentTypes.Liftup && !liftUp && !deadFishAndLiftUp) show = false;
        if (current.Type === ExtraEquipmentTypes.DeadfishCollector && !deadFish && !deadFishAndLiftUp) show = false;
        if (current.Type === ExtraEquipmentTypes.BottomRing) hideQuantity = true;

        acc[current.Id] = {
          NetId: this.netId,
          ExtraEquipmentTypeId: current.Id,
          Show: show,
          HideQuantity: hideQuantity,
        };
        return acc;
      }, {});

      // Inject values that exists on extra equipment registered on net
      for (const equipmentOnNet of this.extraEquipmentForNet || []) {
        this.extraEquipmentForNet[equipmentOnNet.ExtraEquipmentTypeId];
        this.extraEquipemntValues[equipmentOnNet.ExtraEquipmentTypeId] = {
          ...this.extraEquipemntValues[equipmentOnNet.ExtraEquipmentTypeId],
          ...equipmentOnNet,
        };
      }

      this.extraEquipemntValuesTrackerObject = { ...JSON.parse(JSON.stringify(this.extraEquipemntValues)) };
      this.locked =
        this.net.Locked || this.netIsLinkedToAnalysis(this.net.NetDimension) || this.offerIsOrder ? true : false;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async getNet(netId: number) {
    try {
      this.net = await this.netService.get(netId + '?$expand=NetDimension($expand=DeadFishSystem)');
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  setSinkerTube(selectedId: number | undefined, typeId: number) {
    this.extraEquipemntValues[typeId].SinkerTubeId = selectedId;
  }

  protected getSinkerTubeOptions(type: number) {
    return new Promise((res) => {
      res(this.sinkerTubes.filter((st) => st.TypeId == type));
    });
  }

  protected getLabel(type: ExtraEquipmentTypes, property: 'value1' | 'value2' | 'sinkerTubeId' | 'comment') {
    if (type == ExtraEquipmentTypes.Liftup || type == ExtraEquipmentTypes.DeadfishCollector) {
      if (property === 'value1') return 'general.minWeight';
      if (property === 'value2') return 'general.maxWeight';
      if (property === 'comment') return 'general.comment';
    }
    if (
      type == ExtraEquipmentTypes.BottomRing ||
      type == ExtraEquipmentTypes.SingleSinker ||
      type == ExtraEquipmentTypes.CenterSinker
    ) {
      if (property === 'sinkerTubeId') return 'general.sinker';
    }

    if (
      type == ExtraEquipmentTypes.DepthBottomRing ||
      type == ExtraEquipmentTypes.DepthCenterSinker ||
      type == ExtraEquipmentTypes.DepthSingleSinker
    ) {
      if (property === 'value1') return 'general.meter';
    }
  }

  async getAllExtraEquipmentTypes() {
    try {
      this.extraEquipmentTypes = await this.extraEquipmentTypeService.getAll();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  async getAllSinkerTubes() {
    try {
      this.sinkerTubes = await this.sinkerTubeService.getAll();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  async getExtraEquipmentForNet(netId: number) {
    try {
      this.extraEquipmentForNet = await this.extraEquipmentService.getAll(
        `?$filter=NetId eq ${netId}&$expand=ExtraEquipmentType`
      );
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  async save({ reloadAfterSave = true }) {
    try {
      const extraEquipmentsToSave = Object.values(this.extraEquipemntValues).map((eq) => {
        const copy = { ...eq };
        // Remove Extra properties
        delete copy['Show'];
        delete copy['HideQuantity'];
        //
        return copy;
      });

      if (this.extraEquipmentForNet.length) {
        await Promise.all(
          extraEquipmentsToSave.map((e) => this.extraEquipmentService.put(e as unknown as ExtraEquipment, e.Id))
        );
      } else {
        await Promise.all(
          extraEquipmentsToSave.map((e) => this.extraEquipmentService.post(e as unknown as ExtraEquipment))
        );
      }

      if (reloadAfterSave) await this.setup();
    } catch (error) {
      this.errorService.handleError(error);
      throw Error();
    }
  }

  async saveAndChangeTab(direction: ChangeTabDirection) {
    try {
      if (!this.utility.areEqual(this.extraEquipemntValues, this.extraEquipemntValuesTrackerObject, true)) {
        await this.save({ reloadAfterSave: false });
        this.toast?.showSuccess('general.saved');
      }
      void this.doChangeTab(direction);
    } catch (error) {
      //
    }
  }

  protected canDeactivate() {
    this.setCanChangeTab();
  }

  protected detached() {
    this.unsubscribe();
  }

  protected deactivate() {
    this.unsubscribe();
  }
}
