import { autoinject, bindable, TaskQueue } from "aurelia-framework";
import "./simple-offer-nav-bar.scss";

@autoinject
export class SimpleOfferNavBar {
  @bindable private showNextButton: boolean = true;
  @bindable private showPrevButton: boolean = true;
  @bindable private showCloseButton: boolean = true;
  @bindable private nextTabText: string = null;
  @bindable private nextButtonActive: boolean = true;
  @bindable private prevButtonActive: boolean = true;

  constructor(private element: Element, private taskQueue: TaskQueue) {}

  private validateForm(direction: string = 'next') {
    const evt = new CustomEvent('validate-form', {
      bubbles: true,
      detail: direction
    });  
    this.taskQueue.queueMicroTask(() => {       
      this.element.dispatchEvent(evt);  
    });
  }

  private nextTab(event) {
    const evt = new CustomEvent('request-next-tab', {
      bubbles: true,
      detail: event.detail
    });  
    this.taskQueue.queueMicroTask(() => {       
      this.element.dispatchEvent(evt);  
    });
  }

  private prevTab(event) {
    const evt = new CustomEvent('request-prev-tab', {
      bubbles: true,
      detail: event.detail
    });  
    this.taskQueue.queueMicroTask(() => {       
      this.element.dispatchEvent(evt);  
    });
  }

}
