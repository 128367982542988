import { autoinject } from 'aurelia-dependency-injection';
import { ErrorService } from 'services/error-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { NetOfferService } from 'services/net-offer-service';
import { Models } from '../../models/core';
import { ToastService } from 'services/toast-service';

export type ChangeTabDirection = 'next' | 'prev';
@autoinject()
export class SimpleOfferTabBase {
  protected offerIsOrder = false;
  private tabIndex?: number;

  protected subscribers: Subscription[] = [];

  constructor(
    protected offerService: NetOfferService,
    protected errorService: ErrorService,
    private aggregator?: EventAggregator,
    protected toast?: ToastService
  ) {
    this.offerService = offerService;

    this.subscribers.push(
      this.aggregator?.subscribe('changeTab', (tabIndex: number) => {
        this.tabIndex = tabIndex;
      })
    );
  }

  protected async setOfferIsOrder(offerId: number) {
    try {
      const offer = await this.offerService.get(offerId);
      if (offer.OfferStatusId == 8) {
        this.offerIsOrder = true;
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected netIsLinkedToAnalysis(netDimension: Models.NetDimension) {
    if (!netDimension) return false;
    return (
      !!netDimension.AnalysisVariantId ||
      !!netDimension.AnalysisVariant2Id ||
      !!netDimension.AnalysisVariant3Id ||
      !!netDimension.AnalysisVariant4Id ||
      !!netDimension.AnalysisVariant_2Id ||
      !!netDimension.AnalysisVariant2_2Id ||
      !!netDimension.AnalysisVariant3_2Id ||
      !!netDimension.AnalysisVariant4_2Id
    );
  }

  protected doChangeTab(direction: ChangeTabDirection) {
    if (!this.aggregator) return;
    if (direction === 'prev') {
      this.aggregator.publish('triggerPrevTab', true);
    } else {
      this.aggregator.publish('triggerNextTab', true);
    }
  }

  protected setCanChangeTab() {
    this.aggregator.publish('changeTab-success', this.tabIndex);
  }

  protected unsubscribe() {
    for (const subscriber of this.subscribers) {
      subscriber?.dispose();
    }
  }
}
