import { DialogService } from 'aurelia-dialog';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, LogManager } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-framework';
import { Router, RouterConfiguration } from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ImpregnationService } from 'services/impregnation-service';
import { NetOfferService } from 'services/net-offer-service';
import { NetService } from 'services/net-service';
import { OfferStatusService } from 'services/offer-status-service';
import { ReportService } from 'services/report-service';
import { ToastService } from 'services/toast-service';
import { HistoryDialog } from './../history-dialog/history-dialog';
import { AuditLogService } from './../../services/audit-log-service';
import tippy from 'tippy.js';
import { I18N } from 'aurelia-i18n';
import * as moment from 'moment';
import { LostOfferDialog } from 'components/offer/lost-offer-dialog/lost-offer-dialog';
import { NetOfferLostInfoService } from 'services/net-offer-lost-info-service';
import { Logger } from 'aurelia-logging';

const logger: Logger = LogManager.getLogger('simple-offer-detail');

@autoinject
export class SimpleOfferDetail {
  public netoffer: Models.NetOffer;
  public net: Models.Net;
  public priceEstimate: any;

  public statuses: Array<Models.OfferStatus> = [];
  public historicPriceInfoText: string;
  public impregnation: Models.Impregnation;

  protected is2021Net = false;
  protected netLoaded = false;

  public downloadOfferSpecificationProgress: boolean = false;

  private subscriptions: Subscription[] = [];

  private subRoutes: Array<any> = [
    {
      route: [''],
      name: 'simple-offer-tab-dimension',
      moduleId: PLATFORM.moduleName('./simple-offer-tab-dimension'),
      tabindex: 0,
      entityShortName: 'simple-offer',
    },
    {
      route: ['netting'],
      name: 'simple-offer-tab-netting',
      moduleId: PLATFORM.moduleName('./simple-offer-tab-netting'),
      tabindex: 1,
      entityShortName: 'simple-offer',
    },
    {
      route: ['analysis'],
      name: 'simple-offer-tab-analysis',
      moduleId: PLATFORM.moduleName('./simple-offer-tab-analysis'),
      tabindex: 2,
      entityShortName: 'simple-offer',
    },
    {
      route: ['rope'],
      name: 'simple-offer-tab-rope',
      moduleId: PLATFORM.moduleName('./simple-offer-tab-rope'),
      tabindex: 3,
      entityShortName: 'simple-offer',
    },
    {
      route: ['center-bottom'],
      name: 'simple-offer-tab-center-bottom',
      moduleId: PLATFORM.moduleName('./simple-offer-tab-center-bottom'),
      tabindex: 4,
      entityShortName: 'simple-offer',
    },
    {
      route: ['extra-equipment'],
      name: 'simple-offer-tab-extra-equipment',
      moduleId: PLATFORM.moduleName('./simple-offer-tab-extra-equipment'),
      tabindex: 5,
      entityShortName: 'simple-offer',
    },
    {
      route: ['marking'],
      name: 'simple-offer-tab-marking',
      moduleId: PLATFORM.moduleName('./simple-offer-tab-marking'),
      tabindex: 6,
      entityShortName: 'simple-offer',
    },
    {
      route: ['impregnation'],
      name: 'simple-offer-tab-impregnation',
      moduleId: PLATFORM.moduleName('./simple-offer-tab-impregnation'),
      tabindex: 7,
      entityShortName: 'simple-offer',
    },
    {
      route: ['pricing'],
      name: 'simple-offer-tab-pricing',
      moduleId: PLATFORM.moduleName('./simple-offer-tab-pricing'),
      tabindex: 8,
      entityShortName: 'simple-offer',
    },
  ];

  constructor(
    private dialogService: DialogService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private impregnationService: ImpregnationService,
    private netOfferService: NetOfferService,
    private netOfferLostInfoService: NetOfferLostInfoService,
    private netService: NetService,
    private offerStatusService: OfferStatusService,
    private reportService: ReportService,
    private router: Router,
    private toastService: ToastService,
    private i18n: I18N,
    private auditLogService: AuditLogService
  ) {}

  public configureRouter(config: RouterConfiguration, router) {
    config.map(this.subRoutes);
  }

  public activate(params) {
    this.getNetOffer(params.Id);
    setInterval(() => {
      this.is2021Net = !this.is2021Net;
    }, 1000);
    this.getTotalPriceEstimate(params.Id);

    this.offerStatusService
      .getAllCached()
      .then((res) => {
        this.statuses = res.sort((a, b) => (a.SortIndex > b.SortIndex ? 1 : -1)).filter((x) => !x.IsDeleted);
      })
      .catch((err) => this.errorService.handleError(err));

    this.subscriptions.push(
      this.eventAggregator.subscribe('net-standard-changed', (standard: Models.NetStandard) => {
        this.is2021Net = standard?.Version === Models.NetStandardVersion.NS9415_2021;

        setTimeout(() => {
          this.netLoaded = false;
        }, 1);

        setTimeout(() => {
          this.netLoaded = true;
        }, 1);
      }),
      this.eventAggregator.subscribe('offer-refresh', (e) => {
        switch (e) {
          case 'price':
            // TODO: dont get price for now
            // this.getTotalPriceEstimate(this.netoffer.Id);
            break;

          case 'impregnation':
            // TODO: dont get antifouling for now
            // this.getImpregnation(this.net.ImpregnationId);
            break;

          case 'offer':
            this.getNetOffer(this.netoffer.Id);
            break;

          default:
            break;
        }
      })
    );
  }

  private getNetOffer(id) {
    this.netOfferService
      .get(id + '?$expand=Customer,Contact,OfferStatus,NetOfferLostInfo')
      .then((res) => {
        this.netoffer = res;
        logger.debug('netOffer', this.netoffer);
        this.getNet(this.netoffer.NetId);
      })
      .catch((err) => this.errorService.handleError(err));
  }

  public detached() {
    if (this.subscriptions?.length) {
      for (const sub of this.subscriptions) {
        sub?.dispose();
      }
    }
  }

  public deactivate() {
    if (this.subscriptions?.length) {
      for (const sub of this.subscriptions) {
        sub?.dispose();
      }
    }
  }

  private getTotalPriceEstimate(id) {
    this.netOfferService
      .getTotalPriceEstimate(id)
      .then((res) => {
        this.priceEstimate = res;

        if (this.priceEstimate.IsHistoricPrices) {
          let formattedDate = moment.utc(this.priceEstimate.PricesCalculated).local().format('DD.MM.YYYY HH:mm:ss');
          this.historicPriceInfoText = this.i18n.tr('offer.historicpriceinfo', {
            formattedDate: formattedDate,
          });
        }
        setTimeout(() => {
          tippy('.priceestimate');
        });
      })
      .catch((err) => {
        // ignore errors on this
      });
  }

  private getNet(id) {
    this.netService
      .get(id + '?$expand=NetDimension($expand=DesignType,NetStandard)')
      .then((res) => {
        this.net = res;
        this.is2021Net = res.NetDimension.NetStandard?.Version === Models.NetStandardVersion.NS9415_2021;
        this.getImpregnation(this.net.ImpregnationId);
        this.netLoaded = true;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getImpregnation(id) {
    this.impregnationService
      .get(id + '?$expand=ImpregnationType')
      .then((res) => {
        this.impregnation = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private confirmBeforeDeleteOffer() {
    return this.dialogService
      .open({
        viewModel: Prompt,
        model: {
          header: 'dialog.deleteOfferHeading',
          message: 'dialog.deleteOfferAreYouSure',
          actions: {
            delete: { enabled: true, t: 'dialog.deleteOffer' },
            save: { enabled: false },
            cancel: { enabled: true, t: 'dialog.cancel' },
            dontsave: { enabled: false },
          },
        },
      })
      .whenClosed((response) => {
        if (response.wasCancelled) {
          return false;
        } else {
          const result = response.output;
          if (result === 'delete') {
            this.deleteOffer();
            return false;
          } else {
            return true;
          }
        }
      });
  }

  private deleteOffer() {
    this.netOfferService
      .delete(this.netoffer.Id)
      .then((res) => {
        this.toastService.showSuccess('offer.deleted');
        this.router.navigateToRoute('offer-list');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private createNetOrder() {
    if (this.net.NetDimension.Ns9415Compliant && !this.netoffer.ApprovedQualityCheck) {
      this.toastService.showError('offer.cannotConvertToOrderNotApproved', 'offer.notQualityCheckedYet');
    } else {
      this.router.navigate('#/offer/' + this.netoffer.Id + '/net/' + this.netoffer.NetId + '/convert');
    }
  }

  private downloadOfferSpecification() {
    //offer.notQualityCheckedYet
    //offer.printSpecificationWithoutApprovingNet

    if (this.downloadOfferSpecificationProgress) {
      return;
    }

    if (this.net.NetDimension.Ns9415Compliant && !this.netoffer.ApprovedQualityCheck) {
      return this.dialogService
        .open({
          viewModel: Prompt,
          model: {
            header: 'offer.notQualityCheckedYet',
            message: 'offer.printSpecificationWithoutApprovingNet',
            actions: {
              delete: { enabled: false },
              continue: { enabled: true, t: 'dialog.docontinue' },
              cancel: { enabled: true, t: 'dialog.cancel' },
              dontsave: { enabled: false },
            },
          },
        })
        .whenClosed((response) => {
          if (response.wasCancelled) {
            return false;
          } else {
            const result = response.output;
            if (result === 'continue') {
              this.doShowSpecification();
              return false;
            } else {
              return true;
            }
          }
        });
    } else {
      this.doShowSpecification();
      return false;
    }
  }

  private doShowSpecification() {
    this.downloadOfferSpecificationProgress = true;
    this.reportService
      .getSpecificationReport(this.netoffer.NetId)
      .then((res) => {
        this.toastService.showSuccess('document.downloaded');
        this.downloadOfferSpecificationProgress = false;
      })
      .catch((err) => {
        this.errorService.handleError(err);
        this.downloadOfferSpecificationProgress = false;
      });
  }

  public changeNetOfferStatus(netofferId, statusId): Promise<void> {
    logger.debug('changeNetOfferStatus', netofferId, statusId);
    if (statusId === 10) {
      const confirmModel = {};
      return this.dialogService
        .open({
          viewModel: LostOfferDialog,
          model: confirmModel,
          lock: false,
          position: (mc, mo) => {},
        })
        .whenClosed((response) => {
          if (!response.wasCancelled) {
            return this.setNetOfferStatus(netofferId, statusId, response.output);
          } else {
            return Promise.reject<void>();
          }
        });
    } else {
      return this.setNetOfferStatus(netofferId, statusId);
    }
  }

  private setNetOfferStatus(netofferId, statusId, netOfferLostInfo = undefined): Promise<void> {
    this.netoffer.Contact = null;
    this.netoffer.Customer = null;
    this.netoffer.OfferStatus = null;
    this.netoffer.OfferStatusId = statusId;
    if (this.netoffer.NetOfferLostInfoId && netOfferLostInfo) {
      this.netoffer.NetOfferLostInfoId = null;
    }
    this.netoffer.NetOfferLostInfo = netOfferLostInfo;

    logger.debug('lost', netOfferLostInfo, this.netoffer.NetOfferLostInfoId);
    return this.netOfferService
      .put(this.netoffer, netofferId)
      .then((result) => {
        this.eventAggregator.publish('dropdownClose', 1);
        this.toastService.showSuccess('offer.updated');

        this.router.navigateToRoute(this.router.currentInstruction.config.name, this.router.currentInstruction.params, {
          replace: true,
        });
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private showHistory() {
    let viewModel = {
      EntityType: 'Net',
      EntityId: this.netoffer.NetId,
    };

    this.dialogService.open({
      viewModel: HistoryDialog,
      model: viewModel,
      lock: false,
      position: (mc, mo) => {},
    });
  }

  private nextTab() {
    this.eventAggregator.publish('requestNextTab', true);
  }

  private prevTab() {
    this.eventAggregator.publish('triggerPrevTab', true);
  }
}
