import { LoopService } from './../../services/loop-service';
import { EquipmentService } from 'services/equipment-service';
import { NetDimensionService } from 'services/net-dimension-service';
import { NetService } from 'services/net-service';
import { DeadFishSystemConnectionPointService } from './../../services/dead-fish-system-connection-point-service';
import { DeadFishSystemSetupService } from './../../services/dead-fish-system-setup-service';
import { DeadFishSystemService } from './../../services/dead-fish-system-service';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { DialogService } from 'aurelia-dialog';
import { ErrorService } from 'services/error-service';
import { I18N } from 'aurelia-i18n';
import { ToastService } from 'services/toast-service';
import { Utility } from '../../utility';
import { ValidationController } from 'aurelia-validation';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { SimpleOfferTabBase } from './simple-offer-tab-base';
import { NetOfferService } from 'services/net-offer-service';

@autoinject
export class SimpleOfferTabCenterBottom extends SimpleOfferTabBase {
  private nextTabIndex: number = null;
  private originalObject;
  private formIsValid = true;
  private locked = false;

  private netId: number;
  private dimension: Models.NetDimension;

  private changeTabSubscription: Subscription;

  private allConnectionPoints: Array<any>;
  private allDeadFishSystemSetups: Array<any>;
  private allDeadFishSystemSetups2: Array<any>;

  private innerBottomOptions: Array<any>;
  private outerBottomOptions: Array<any>;
  private spagetthiSinkerOptions: Array<any>;

  private deadFishSystemSetupOptions: Array<any>;
  private deadFishSystemSetupOptions2: Array<any>;

  private equipments: Array<any>;
  private loops: Array<Models.Loop>;

  private equipmentNewFormVisible = false;
  private equipmentEditFormVisible: number = null;

  private loopNewFormVisible = false;
  private loopEditFormVisible: number = null;

  private subscriptionLoopEditClose: Subscription;
  private subscriptionLoopReset: Subscription;
  private subscription6: Subscription;
  private subscription7: Subscription;

  constructor(
    private dialogService: DialogService,
    private eventAggregator: EventAggregator,
    private equipmentService: EquipmentService,
    private netService: NetService,
    private netDimensionService: NetDimensionService,
    private deadFishSystemService: DeadFishSystemService,
    private loopService: LoopService,
    private deadFishSystemSetupService: DeadFishSystemSetupService,
    private deadFishConnectionPointService: DeadFishSystemConnectionPointService,
    private i18n: I18N,
    private toastService: ToastService,
    private validationController: ValidationController,
    private utility: Utility,
    private router: Router,
    errorService: ErrorService,
    netOfferService: NetOfferService
  ) {
    super(netOfferService, errorService);
  }

  private async activate(params) {
    if (this.router.baseUrl.includes('offer')) {
      await this.setOfferIsOrder(params.Id);
    }
    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.changeTabSubscription = this.eventAggregator.subscribe('changeTab', (tabIndex) => {
      this.nextTabIndex = tabIndex;
    });

    this.netId = params.NetId || params.Id;
    this.getNet(this.netId);

    this.subscription6 = this.eventAggregator.subscribe('equipmentListReset', (value) => {
      this.equipmentEditFormVisible = null;
      this.getEquipments();
      this.eventAggregator.publish('offer-refresh', 'price');
    });

    this.subscription7 = this.eventAggregator.subscribe('equipmentFormEditClose', (value) => {
      this.getEquipments();
    });

    this.eventAggregator.subscribe('equipmentFormNewClose', (value) => {
      this.equipmentNewFormVisible = false;
    });

    // Update loops array when creating new loops
    this.subscriptionLoopReset = this.eventAggregator.subscribe('loopListReset', (value) => {
      this.loopEditFormVisible = null;
      this.getLoops(this.netId);
      this.eventAggregator.publish('offer-refresh', 'price');
    });

    this.subscriptionLoopEditClose = this.eventAggregator.subscribe('loopFormEditClose', (value) => {
      return this.getLoops(this.netId);
    });

    this.eventAggregator.subscribe('loopFormNewClose', (value) => {
      this.loopNewFormVisible = false;
    });

    this.getLoops(this.netId);
  }

  private async getNet(id) {
    this.netService
      .get(id + '?$expand=NetDimension')
      .then(async (res) => {
        this.dimension = res.NetDimension;
        this.locked = res.Locked || this.netIsLinkedToAnalysis(res.NetDimension) || this.offerIsOrder ? true : false;

        this.updateOriginalObject();
        this.addValidationRules();

        this.setupDeadFishSystemSetupOptions();
        this.setupConnectionPointOptions();

        this.getEquipments();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private async setupConnectionPointOptions() {
    if (!this.allConnectionPoints) {
      this.allConnectionPoints = await this.deadFishConnectionPointService.getAllCached();
    }

    // find valid options for connection points based on type and rules for crossropes (all lifting ropes continue as crossropes)
    this.innerBottomOptions = JSON.parse(
      JSON.stringify(
        this.allConnectionPoints.filter(
          (x) =>
            x.InnerBottom &&
            (!x.MaxCrossRopes || this.dimension.LiftingRopes <= x.MaxCrossRopes) &&
            (!x.MinCrossRopes || this.dimension.LiftingRopes >= x.MinCrossRopes)
        )
      )
    );
    this.outerBottomOptions = JSON.parse(JSON.stringify(this.allConnectionPoints.filter((x) => x.OuterBottom)));
    this.spagetthiSinkerOptions = JSON.parse(JSON.stringify(this.allConnectionPoints.filter((x) => x.SpagetthiSinker)));
  }

  private async setupDeadFishSystemSetupOptions() {
    if (!this.allDeadFishSystemSetups) {
      this.allDeadFishSystemSetups = await this.deadFishSystemSetupService.getAllCached();
      this.allDeadFishSystemSetups2 = this.allDeadFishSystemSetups;
    }

    if (this.dimension.DeadFishSystemId) {
      let newDeadFishSetupOptions = null;
      let newDeadFishSetupOptions2 = null;

      if (this.dimension.DeadFishSystemId == 1) {
        newDeadFishSetupOptions = this.allDeadFishSystemSetups.filter((x) => x.ForDeadFishCollector);
      } else if (this.dimension.DeadFishSystemId == 2) {
        newDeadFishSetupOptions = this.allDeadFishSystemSetups.filter((x) => x.ForLiftUp);
      } else {
        newDeadFishSetupOptions = this.allDeadFishSystemSetups.filter((x) => x.ForDeadFishCollector);
        newDeadFishSetupOptions2 = this.allDeadFishSystemSetups.filter((x) => x.ForLiftUp);
      }

      // special handling for coned and spagetthi nets
      if (this.dimension.NetShapeId == 3 || this.dimension.NetShapeId == 7) {
        newDeadFishSetupOptions = newDeadFishSetupOptions.filter((x) => x.ForConedAndSpagetthi);
        newDeadFishSetupOptions2 = newDeadFishSetupOptions.filter((x) => x.ForConedAndSpagetthi);
      }

      // clear the DeadFishSystemSetupId if anything is selected and that is not a valid options anymore
      if (
        this.dimension.DeadFishSystemSetupId &&
        !newDeadFishSetupOptions?.find((x) => x.Id == this.dimension.DeadFishSystemSetupId)
      ) {
        this.dimension.DeadFishSystemSetupId = null;
      }

      if (
        this.dimension.DeadFishSystemSetupId2 &&
        !newDeadFishSetupOptions2?.find((x) => x.Id == this.dimension.DeadFishSystemSetupId2)
      ) {
        this.dimension.DeadFishSystemSetupId2 = null;
      }

      this.deadFishSystemSetupOptions = null;
      this.deadFishSystemSetupOptions2 = null;

      setTimeout(() => {
        this.deadFishSystemSetupOptions = JSON.parse(JSON.stringify(newDeadFishSetupOptions));
        this.deadFishSystemSetupOptions2 = JSON.parse(JSON.stringify(newDeadFishSetupOptions2));
      });
    }
  }

  private detached() {
    if (this.changeTabSubscription) {
      this.changeTabSubscription.dispose();
    }
  }

  private deactivate() {
    if (this.changeTabSubscription) {
      this.changeTabSubscription.dispose();
    }

    if (this.subscription6) {
      this.subscription6.dispose();
    }
    if (this.subscription7) {
      this.subscription7.dispose();
    }

    if (this.subscriptionLoopEditClose) {
      this.subscriptionLoopEditClose.dispose();
    }
    if (this.subscriptionLoopReset) {
      this.subscriptionLoopReset.dispose();
    }
  }

  private addValidationRules() {
    /*     ValidationRules.ensure("NetTypeId")
      .required()
      .withMessage(this.i18n.tr("general.requiredField"))
      .on(this.net); */
  }

  private validateForm() {
    this.validationController.validate().then((result) => {
      if (result.valid) {
        this.formIsValid = true;
      } else {
        this.formIsValid = false;
      }
    });
  }

  private async validateAsync(): Promise<any> {
    return new Promise<void>(async (resolve, reject) => {
      this.validationController
        .validate()
        .then((result) => {
          if (result.valid) {
            this.formIsValid = true;
            resolve();
          } else {
            this.formIsValid = false;
            reject();
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private updateOriginalObject() {
    if (!this.dimension) {
      return;
    }
    this.originalObject = JSON.parse(JSON.stringify(this.dimension));
  }

  private deadFishSystemChanged(event) {
    if (!event?.detail) {
      return;
    }

    this.dimension.DeadFishSystemId = event.detail.value;

    if (this.dimension.DeadFishSystemId >= 2) {
      this.dimension.DeadFishFunnel = this.dimension.SlopeAngle < 45;
    } else {
      if (this.dimension.DeadFishFunnel == undefined) {
        this.dimension.DeadFishFunnel = false;
      }
    }

    this.setupDeadFishSystemSetupOptions();
    this.setupConnectionPointOptions();
  }

  private deleteEquipment(id) {
    this.equipmentService
      .delete(id)
      .then((res) => {
        this.toastService.showSuccess('equipment.deleted');
        this.getEquipments();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getEquipments() {
    this.equipmentService
      .getAll('?$filter=IsCenterBottomEquipment eq true and NetId eq ' + this.netId + '&$expand=EquipmentType')
      .then((res) => {
        this.equipments = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private async save(): Promise<any> {
    if (this.dimension.DeadFishSystemId <= 2) {
      this.dimension.DeadFishSystemSetupId2 = null;
      this.dimension.DeadFishSystemSetup2 = null;
    }
    return new Promise<void>(async (resolve, reject) => {
      this.netDimensionService
        .put(this.dimension, this.dimension.Id)
        .then((res) => {
          this.toastService.showSuccess('centerbottom.updated');
          this.updateOriginalObject();
          resolve();
        })
        .catch((err) => this.errorService.handleError(err));
    });
  }

  private changeTab(direction: string = 'next') {
    if (direction === 'prev') {
      this.eventAggregator.publish('triggerPrevTab', true);
    } else {
      this.eventAggregator.publish('triggerNextTab', true);
    }
  }

  private getLoops(netId) {
    this.loopService
      .getAll(
        '?$expand=LoopPositionHorizontal,LoopType,LoopPositionVertical&$filter=LoopPositionHorizontalId eq 6 and NetId eq ' +
          netId
      )
      .then((res) => {
        this.loops = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deleteLoop(id) {
    this.loopService
      .delete(id)
      .then((res) => {
        this.toastService.showSuccess('loop.deleted');
        this.getLoops(this.netId);
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private saveAndChangeTab(direction: string = 'next') {
    // does it validate ?
    this.validateAsync()
      .then(() => {
        // check if different
        if (!this.utility.areEqual(this.dimension, this.originalObject, true)) {
          // save
          this.save().then((res) => {
            this.changeTab(direction);
          });
        } else {
          this.changeTab(direction);
        }
      })
      .catch((error) => {
        this.toastService.showWarning('general.checkValidationErrors');
      });
  }

  private canDeactivate() {
    this.validateForm();

    /*  if (!this.formIsValid) {
      this.toastService.showWarning("general.checkValidationErrors");
      return false;
    }
*/
    if (this.originalObject && !this.utility.areEqual(this.dimension, this.originalObject, true) && !this.locked) {
      // tslint:disable-next-line:no-console
      return this.dialogService
        .open({
          viewModel: Prompt,
          model: {
            header: 'dialog.pleaseConfirmHeader',
            message: 'dialog.unsavedChangesText',
          },
        })
        .whenClosed((response) => {
          if (response.wasCancelled) {
            return false;
          } else {
            const result = response.output;
            if (result === 'save') {
              // save the nettingtype and let that function handle the rest of the logic
              this.save();
              return false;
            } else {
              this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
              return true;
            }
          }
        });
    } else {
      this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
      return true;
    }
  }
}
